




































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { FundIncomeRecordDto, FundUserDto, JobDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import request from "@/utils/request";

declare module "vue/types/vue" {
  interface Vue {
    [key: string]: any;
  }
}

@Component({
  name: "FundUserList",
  components: {
    PagedTableView,
    AbSelect,
  },
})
export default class FundUserList extends Vue {
  currentPath = "";
  currentValue: any = {};
  pathList = [
    {
      key: "",
      value: null,
    },
  ];
  schemas: any = [];
  parameters: any = []; // 查询参数
  selectedParameters: any = []; // 已选择查询参数
  fields: any = []; // 列表显示字段
  selectedFields: any = []; // 已选择列表显示字段
  currentDto = "";
  currentApi = "";
  codes: string[] = []; // 生成代码

  created() {
    const baseURL = process.env.VUE_APP_BASE_API;
    const json = `${baseURL}/swagger/v1/swagger.json`;

    let result = request
      .request({
        url: json,
      })
      .then((res) => {
        if (res.data) {
          this.schemas = res.data.components.schemas;
          for (let i in res.data.paths) {
            if (i.indexOf("/GetAll") != -1) {
              let obj = {
                key: i,
                value: res.data.paths[i],
              };
              this.pathList.push(obj);
            }
          }
        }
      });
  }

  selectCurrentPath(e: any) {
    this.parameters = [];
    this.fields = [];
    this.pathList.forEach((item: any) => {
      if (item.key == e) {
        this.currentValue = item;
        let schamePath =
          item["value"]["get"]["responses"]["200"]["content"]["text/plain"][
            "schema"
          ]["$ref"];
        if (schamePath) {
          const index = schamePath.lastIndexOf("/");
          const schame = schamePath.substring(index + 1);
          const resultDto = this.schemas[schame]; // FundDtoPagedResultDto
          const dtoName = resultDto["properties"]["items"]["items"]["$ref"]; // #/components/schemas/FundDto
          const i = dtoName.lastIndexOf("/");
          this.currentDto = dtoName.substring(i + 1); // FundDto
          if (this.currentDto.indexOf("Dto") != -1) {
            this.currentApi = this.currentDto.substr(
              0,
              this.currentDto.length - 3
            );
            this.currentApi =
              this.currentApi.charAt(0).toLocaleLowerCase() +
              this.currentApi.substr(1); // fund
          }
          let selectedDto = this.schemas[this.currentDto].properties;
          for (let i in selectedDto) {
            let obj = {
              name: i,
              description: selectedDto[i]["description"],
              nullable: selectedDto[i]["nullable"],
              type: selectedDto[i]["type"],
            };
            this.fields.push(obj);
          }
        }
        for (let i in item.value.get.parameters) {
          this.parameters.push(item.value.get.parameters[i]);
        }
      }
    });
  }

  generateCode() {
    let codes = [];
    codes.push("<template>");
    codes.push("  <div>");
    codes.push(
      '    <paged-table-view :search-function="fetchData" :query-form="queryForm">'
    );
    codes.push("      <template #condition>");

    // 查询条件
    if (this.selectedParameters) {
      this.selectedParameters.forEach((item: any) => {
        // 查询参数首字母需要小写
        item.name =
          item.name.charAt(0).toLocaleLowerCase() + item.name.substr(1);
        codes.push("        <el-form-item>");
        codes.push(
          '          <el-input v-model="queryForm.' +
            item.name +
            '" clearable type="text" placeholder="请输入' +
            item.description +
            '查询" />'
        );
        codes.push("        </el-form-item>");
      });
    }

    codes.push("        <el-form-item>");
    codes.push(
      '          <ab-select :list="typeList" item-value="text" item-key="value" class="width-100" placeholder="请选择基金类型" v-model="queryForm.type" @change="fetchFundList" />'
    );
    codes.push("        </el-form-item>");
    codes.push("        <el-form-item>");
    codes.push(
      '          <ab-select :list="fundList" item-value="name" item-key="id" class="width-100" placeholder="请选择基金" v-model="queryForm.fundId" />'
    );
    codes.push("        </el-form-item>");
    codes.push("        <el-form-item>");
    codes.push(
      '          <el-date-picker v-model="queryForm.startDate" placeholder="起始时间" value-format="yyyy-MM-dd" format="yyyy-MM-dd" />'
    );
    codes.push("        </el-form-item>");
    codes.push("      </template>");
    codes.push("      <template #buttons>");
    codes.push('        <el-divider class="query-form-divider" />');
    codes.push("      </template>");
    codes.push("      <template #table>");
    codes.push(
      '        <el-table-column label="序号" type="index" width="50" />'
    );

    // 列表展示字段
    if (this.selectedFields) {
      this.selectedFields.forEach((item: any) => {
        codes.push(
          '        <el-table-column label="' +
            item.description +
            '" prop="' +
            item.name +
            '" />'
        );
      });
    }

    codes.push('        <el-table-column label="操作" width="180">');
    codes.push('          <template slot-scope="scope">');
    codes.push(
      '            <el-button size="mini" @click="jumpDetail(scope.row)">查看详情</el-button>'
    );
    codes.push(
      '            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>'
    );
    codes.push("          </template>");
    codes.push("        </el-table-column>");
    codes.push("      </template>");
    codes.push("    </paged-table-view>");
    codes.push("  </div>");
    codes.push("</template>");
    codes.push("");
    codes.push('<script lang="ts">');
    codes.push('import api from "@/api/index"; //ABP API接口');
    codes.push('import { Vue, Component, Ref } from "vue-property-decorator";');
    codes.push(
      "import {FundDto, FundDtoPagedResultDto, " +
        this.currentDto +
        '} from "@/api/appService";'
    );
    codes.push(
      'import PagedTableView from "@/components/PagedTableView/DataItemDiff.vue";'
    );
    codes.push(
      'import AbSelect from "@/components/AbSelect/DataItemDiff.vue";'
    );
    codes.push(
      'import ExportButton from "@/components/ExportButton/DataItemDiff.vue";'
    );
    codes.push("");
    codes.push("@Component({");
    codes.push('  name: "' + this.currentDto + 'List",');
    codes.push("  components: {");
    codes.push("    PagedTableView,");
    codes.push("    AbSelect,");
    codes.push("    ExportButton");
    codes.push("  },");
    codes.push("})");
    codes.push(
      "export default class " + this.currentDto + "List extends Vue {"
    );
    codes.push("  typeList: any = [];");
    codes.push("  fundList: FundDto[] = [];");
    codes.push("");
    codes.push("  queryForm = {");
    codes.push("  };");
    codes.push("");
    codes.push("  created() {");
    codes.push("    this.fetchEnumType();");
    codes.push("    this.fetchFundList()");
    codes.push("  }");
    codes.push("");
    codes.push("  async fetchFundList() {");
    codes.push("");
    codes.push("    await api.fund.getAll({})");
    codes.push("      .then((res:FundDtoPagedResultDto)=>{");
    codes.push("        this.fundList = res.items??[]");
    codes.push("      })");
    codes.push("  }");
    codes.push("");
    codes.push("  async fetchEnumType() {");
    codes.push("    await api.enumService");
    codes.push("      .getValues({");
    codes.push('        typeName: "FundType"');
    codes.push("      })");
    codes.push("      .then(res => {");
    codes.push("        this.typeList = res;");
    codes.push("      });");
    codes.push("  }");
    codes.push("");
    codes.push("  // 获取表数据");
    codes.push("  fetchData(params: any) {");
    codes.push("    return api." + this.currentApi + ".getAll(params);");
    codes.push("  }");
    codes.push("");
    codes.push("  // 查看详情");
    codes.push("  jumpDetail(row: " + this.currentDto + ") {");
    codes.push("    this.$router.push({");
    codes.push('      name: "",');
    codes.push("      params: { id: row.id!.toString() }");
    codes.push("    });");
    codes.push("  }");
    codes.push("");
    codes.push("  // 删除");
    codes.push("  handleDelete(row: " + this.currentDto + ") {");
    codes.push('    this.$confirm("你确定删除吗?", "提示").then(async () => {');
    codes.push(
      "      await api." +
        this.currentApi +
        ".delete({ id: row.id }).then((res:any) => {"
    );
    codes.push("        this.$message({");
    codes.push('          type: "success",');
    codes.push('          message: "删除成功!"');
    codes.push("        });");
    codes.push("      });");
    codes.push("    });");
    codes.push("  }");
    codes.push("}");
    codes.push("<" + "/script>");
    codes.push('<style scoped lang="scss"></style>');

    this.codes = codes;
  }
}
